@use 'src/utils/scss/media';

.section {
  width: 100vw;
  height: 726px;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-blend-mode: multiply;
  overflow-y: hidden;
  overflow-x: hidden;

  &__image {
    width: 446.389px;
    height: 446.389px;
    transform: rotate(-3.765deg);
    position: absolute;
    right: -75px;
    bottom: 20px;
    flex-shrink: 0;
    z-index: -3;
  }

  &__describe {
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 338px;
    display: flex;
    flex-direction: column;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(115, 115, 115, 0.6) 100%
    );
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;

  &__title {
    color: #646ab0;
    text-align: center;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5.2px;
  }
}

.content {
  color: #646ab0;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 52px;
}
