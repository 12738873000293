@use 'src/utils/scss/media';

.template {
  width: 100%;

  @include media.media-breakpoint(mobile) {
  }
}

.top-button {
  z-index: 1000;
  position: fixed;
  right: 30px;
  bottom: 15px;
}
