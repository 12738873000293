@use "src/utils/scss/media";

.section {
  width: 80vw;
  padding: 0 10vw;
  height: calc(100vh - 78px);
  background-image: url('Asset/BG.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media.media-breakpoint(mobile) {
    height: 100%;
    background-image: none;
    background-color: #fff;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include media.media-breakpoint(mobile) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 44px 25px;
      width: calc(100vw - 50px);
    }
  }
  &__info {
    width: 50%;
    display: flex;
    flex-direction: column;

    @include media.media-breakpoint(mobile) {
      width: 100%;
    }
  }

  &__map {
    width: 50%;
    cursor: pointer;

    @include media.media-breakpoint(mobile) {
      width: 100%;
      height: 100%;
    }
  }
}

.info {
  &__title {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 147px;
    gap: 8px;
    color: #6170B5;
    font-family: Pretendard;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.44px;
    border-bottom: 3px solid #6888BE;
    margin-bottom: 32px;

    @include media.media-breakpoint(mobile) {
      gap: 4px;
      font-size: 18px;
      font-weight: 700;
      width: 67px;
      margin-bottom: 8px;
    }
  }

  &__describe {
    color: #6888BE;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-bottom: 30px;

    @include media.media-breakpoint(mobile) {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 25px;
    }
  }

  &__content {
    display: flex;

    @include media.media-breakpoint(mobile) {
      width: 100%;
      flex-direction: column;
    }
  }
}

.card {
  width: calc(50% - 64px);
  height: calc(100% - 64px);
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color:#FBFBFB;
  margin-right: 19px;

  @include media.media-breakpoint(mobile) {
    background-color: #FFF;
    padding: 0;
    width: 100%;
    margin-bottom: 16px;
  }

  &__logo {
    margin-bottom: 4px;
    margin-right: 8px;

    @include media.media-breakpoint(mobile) {
      width: 16px;
      height: 16px; 
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  
    @include media.media-breakpoint(mobile) {
      margin-bottom: 5px;
    }
  }

  &__title {
    color: #6170B5;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.264px;
    display: flex;
    align-items: center;
    text-align: center;

    @include media.media-breakpoint(mobile) {
      font-size: 14px;
      font-weight: 700;
      line-height: 120%;
    }
  }

  &__content {
    color: #4D4D4D;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;

    @include media.media-breakpoint(mobile) {
      font-size: 16px;
      font-weight: 400;
    }
  }
}