@use 'src/utils/scss/media';

.major-introduce-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  gap: 147px;
}

.major-introduce-info-img {
  position: relative;
  width: 100%;
  max-width: 1517px;
  height: 640px;
  overflow: auto;
  background-size: cover;
  background-position: center;
  background-image: url('./Asset/About_main.png');
}

.major-introduce-info {
  position: absolute;
  bottom: 60px;
  right: 124px;
  width: 629px;
  color: #fff;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.242px;
}

.title {
  color: #6170b5;
  width: fit-content;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  letter-spacing: -0.44px;
  padding-bottom: 5px;
  border-bottom: 3px solid #6888be;
}

.major-professor-section {
  margin-top: 89px;
  // display: flex;
  // flex-wrap: wrap;

  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  // gap: 77px 36px;
  // max-width: 1126px;
  // width: 100%;
  // justify-items: center;
  // align-items: center;

  // @include media.media-breakpoint(mobile) {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  //   gap: 11px 20px;
  //   max-width: 325px;
  //   justify-items: center;
  //   align-items: center;
  // }
}

.item-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(496px, 1fr));
  gap: 32px 16px;
  max-width: 1520px;
  width: 100%;
  justify-items: center;
  align-items: center;

  @include media.media-breakpoint(mobile) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 24px 7px;
    max-width: 327px;
    justify-items: center;
    align-items: center;
  }
}

.main-professor {
  & > img {
    width: 247px;
    height: 329px;
    flex-shrink: 0;
    border-radius: 8px;
  }

  &__title {
    display: flex;
    gap: 12px;
    align-items: baseline;
    margin-top: 34px;
  }

  &__name {
    color: #000;
    font-family: Pretendard;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
    letter-spacing: -0.308px;
  }

  &__role {
    color: var(--Greyscale-black-7, #8c8c8c);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
  }

  &__info {
    margin-top: 4px;
    width: 247px;
    color: #000;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
  }
}

.other-professor-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  gap: 36px 77px;
  max-width: 1127px;
  width: 100%;
  justify-items: center;
  align-items: center;
  margin-bottom: 103.25px;

  @include media.media-breakpoint(mobile) {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    gap: 11px 20px;
    max-width: 325px;
    justify-items: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23px;

    @include media.media-breakpoint(mobile) {
      gap: 6.98px;
    }

    & > img {
      width: 224px;
      height: 299px;
      border-radius: 8px;
      @include media.media-breakpoint(mobile) {
        width: 95.36px;
        height: 133.061px;
        border-radius: 8px;
      }
    }
  }

  &__name {
    color: #000;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @include media.media-breakpoint(mobile) {
      color: #000;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.3px; /* 152.5% */
    }
  }
}

.mobile-major-introduce-section {
  display: flex;
  flex-direction: column;
  padding: 90px 24px 153px 24px;
}

.mobile-major-image {
  display: flex;
  width: calc(100vw + 24px);
  left: -24px;
  position: relative;
  object-fit: cover;
  margin-bottom: 45px;
}

.mobile-major-introduce {
  max-height: 300px;
  margin-bottom: 30px;
  color: #000;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

.mobile-major-introduce-sub {
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 32px */
  letter-spacing: -0.22px;
  margin-top: 30px;
}

.mobile-major-introduce-title {
  color: #6170b5;
  width: fit-content;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 4px;
  border-bottom: 1.5px solid #6888be;
}
