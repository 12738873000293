@use 'src/utils/scss/media';

.container {
  position: relative;
  margin: 0 auto;
  width: 100vw;

  @include media.media-breakpoint(mobile) {
    padding-top: 51px;
    // & > img {
    //   display: flex;
    //   margin: 0 auto;
    //   // width: 375px;
    //   // height: 530px;

    //   max-width: 375px;
    //   max-height: 530px;
    //   height: auto;

    //   flex-shrink: 0;
    // }
  }
}

.mobile-bala-poster {
  display: flex;
  margin: 0 auto;
  // width: 375px;
  // height: 530px;

  width: 100%;
  max-height: 530px;
  height: auto;

  flex-shrink: 0;
}

.bala-poster {
  max-width: 501px;
  width: 100%;
  flex-shrink: 0;
}

.exhibit-introduce-section {
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  //align-items: center;
  padding: 117px 0px 0px 200px;

  @include media.media-breakpoint(mobile) {
    width: calc(100% - 48px);
    padding: 62px 24px 65px 24px;
  }
}

.title {
  color: #6170b5;
  width: fit-content;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  letter-spacing: -0.44px;
  padding-bottom: 8px;
  border-bottom: 3px solid #6888be;

  @include media.media-breakpoint(mobile) {
    color: #6170b5;
    width: fit-content;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 4px;
    border-bottom: 1.5px solid #6888be;
  }
}

.info-section {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  gap: 109px;
  margin-top: 52px;
  margin-bottom: 196px;

  // & > img {
  //   // width: 501px;
  //   // height: 708px;

  //   max-height: 708px;
  //   max-width: 501px;
  //   height: auto;
  //   width: auto;
  //   flex-shrink: 0;
  // }
  &__info {
    width: 783px;
    color: #4d4d4d;

    /* Medium/22pt */
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 35.2px */
    letter-spacing: -0.242px;

    @include media.media-breakpoint(mobile) {
      display: flex;
      margin: 0 auto;
      margin-top: 12px;
      margin-bottom: 41px;
      width: 326px;
      color: #000;

      /* Regular/16pt */
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 25.6px */
    }
  }
}

.category-section {
  &__info {
    margin-top: 14px;
    width: fit-content;
    color: #000;

    /* Medium/20pt */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
    @include media.media-breakpoint(mobile) {
      color: #000;
      margin-top: 15px;

      /* Regular/14pt */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
  }

  &__detail {
    //padding-left: 295px;
    margin-top: 167px;
    margin-bottom: 288px;
    display: flex;
    // 여기를 중간으로 하는 것이 나을지 아니면 그냥 padding 으로 미는것이 나을까..
    justify-content: center;
    gap: 166px;
    flex-wrap: wrap;

    @include media.media-breakpoint(mobile) {
      display: flex;
      justify-content: center;
      padding-left: 0px;
      margin-top: 27px;
      margin-bottom: 56px;
      gap: 42px;
    }
  }

  &__detail-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @include media.media-breakpoint(mobile) {
      gap: 17px;
    }

    & > img {
      @include media.media-breakpoint(mobile) {
        width: 62px;
        height: 62px;
        flex-shrink: 0;
      }
    }
    & > span {
      color: #636fb4;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 38.4px */
      @include media.media-breakpoint(mobile) {
        color: #6170b5;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
      }
    }
  }
}

.exhibit-section {
  display: flex;
  margin-bottom: 135px;
  flex-wrap: wrap;

  @include media.media-breakpoint(mobile) {
    width: 100%;
    gap: 43px;
    margin-bottom: 74px;
  }

  & > img {
    //max-width: 568px;
    max-height: 449px;
    max-width: 100%;
    height: auto;

    @include media.media-breakpoint(mobile) {
      display: flex;
      margin: 0 auto;
      width: 242px;
      height: 191px;
    }
  }

  &__wrapper {
    width: 50%;

    @include media.media-breakpoint(mobile) {
      width: 100%;
    }
  }

  &__info {
    margin-top: 14px;
    width: 100%;
    max-width: 486px;
    color: #000;

    /* Medium/20pt */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
    @include media.media-breakpoint(mobile) {
      color: #000;

      /* Regular/14pt */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
  }

  &__detail {
    display: flex;
    margin-top: 53px;

    @include media.media-breakpoint(mobile) {
      margin-top: 28px;
      width: 100%;
    }
  }

  &__detail-title {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    height: 125px;
    margin-right: 77px;     
    padding-left: 10px;


    @include media.media-breakpoint(mobile) {
      width: 50%;
      margin-right: 0;
      padding-left: 10px;
      
    }
    
    & > span {
      color: #000;
      /* Medium/20pt */
      display: block;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%; /* 32px */
      margin-bottom: 20px;

      @include media.media-breakpoint(mobile) {
        color: #000;
        margin-bottom: 18px;
        /* Bold/14pt */
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  &__detail-title-number {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    @include media.media-breakpoint(mobile) {
      width: 50%;
    }
    & > span {
      color: #000;
      display: block;
      margin-bottom: 18px;
      /* Medium/20pt */
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%; /* 32px */
      @include media.media-breakpoint(mobile) {
        color: #000;
        margin-bottom: 18px;
        /* Bold/14pt */
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  &__detail-info {
    color: #000;
    height: 35px;
    /* Medium/20pt */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
    margin-bottom: 10px;
    margin-left: 20px;

    @include media.media-breakpoint(mobile) {
      color: #000;
      height: 16px;
      margin-bottom: 9px;
      /* Regular/14pt */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }

    &--number {
      height: 25px;
      margin-bottom: 17px;

      @include media.media-breakpoint(mobile) {
        height: 16px;
        margin-bottom: 9px;
      }
    }
  }
}

.committee-section {
      @include media.media-breakpoint(mobile) {
        width: 375px;
      }
  &__member-section {
    margin-top: 20px;

    @include media.media-breakpoint(mobile) {
      margin-top: 23px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__member {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    @include media.media-breakpoint(mobile) {
      margin-bottom: 0;
      align-items: flex-start;
    }
  }

  &__member-group {
    width: 128px;
    color: #000;

    /* Bold/22pt */
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 35.2px */
    letter-spacing: -0.242px;

    @include media.media-breakpoint(mobile) {
      width: 42px;
      margin-right: 26px;
      color: #000;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 14.4px */
    }
  }

  &__member-group-unit {
    display: flex;
    gap:5px;
    color: #4d4d4d;
    /* Medium/22pt */
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 35.2px */
    letter-spacing: -0.242px;

    @include media.media-breakpoint(mobile) {
      display: flex;
      flex-wrap: wrap;
    
      color: #000;
      font-family: Pretendard;
      gap:3px;
      max-width: 245px;
      // max-width: 75%;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 14.4px */
    }
  }
}

.sponsor-section {
  margin-top: 95px;
  margin-bottom: 56px;
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  justify-content: center;

  @include media.media-breakpoint(mobile) {
    margin-top: 0px;
    gap: 15px;
    margin-bottom: 85px;
  }
}

.koreatechIcon {
  @include media.media-breakpoint(mobile) {
    width: 75px;
    height: 22px;
    flex-shrink: 0;
  }
}
.ideIcon {
  @include media.media-breakpoint(mobile) {
    width: 55px;
    height: 20px;
    flex-shrink: 0;
  }
}
.lincIcon {
  @include media.media-breakpoint(mobile) {
    width: 132px;
    height: 22px;
    flex-shrink: 0;
  }
}
.dscIcon {
  @include media.media-breakpoint(mobile) {
    width: 87px;
    height: 22px;
    flex-shrink: 0;
  }
}
.smartHumanInterfaceIcon {
  @include media.media-breakpoint(mobile) {
    width: 104px;
    height: 22px;
    flex-shrink: 0;
  }
}
.seedkeeperIcon {
  @include media.media-breakpoint(mobile) {
    width: 66px;
    height: 17px;
    flex-shrink: 0;
  }
}
