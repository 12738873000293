@use 'src/utils/scss/media';

.header {
  width: calc(100vw - 24px);
  height: 29px;
  display: flex;
  padding: 8px 12px 12px;
  position: absolute;
  justify-content: space-between;
  font-family: Pretendard;
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 1200;

  @include media.media-breakpoint(mobile) {
    background-color: #fff;
    height: 64px;
    width: calc(100vw - 24px);
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    flex-direction: column;

    &--on {
      background-color: rgba(8, 6, 6, 0.6);
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: flex-end;
      padding: 0;
      justify-content: right;
      flex-direction: column;
    }
  }

  &__top {
    width: calc(100% - 28px);
    max-width: 1169px;
    display: flex;
    align-items: center;
    padding: 28.4px 14px 17px;
    background-color: #fff;
    z-index: 999;
    cursor: pointer;

    @include media.media-breakpoint(mobile) {
      width: calc(100% - 48px);
      display: flex;
      padding: 17px 14px;
      justify-content: space-between;

      &--on {
        width: calc(100vw - 48px);
        animation: openToggle 0.4s linear;
        -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
        animation-timing-function: linear;
        background-color: #fff;
        padding: 8px 24px 12px;
        float: right;
        justify-content: flex-end;
      }
    }
  }

  &__logo {
    @include media.media-breakpoint(mobile) {
      display: none;
    }
  }
}

.hamburger {
  display: block;
  height: 22px;
  width: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--on {
    width: 18px;
  }

  &__line1 {
    display: block;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background: #646ab0;
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;

    &--on {
      transform: rotate(45deg);
    }
  }
  &__line2 {
    display: block;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background: #646ab0;
    transition: transform 0.2s ease-in-out;

    &--on {
      transform: scaleY(0);
    }
  }

  &__line3 {
    display: block;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background: #646ab0;
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;

    &--on {
      transform: rotate(-45deg);
    }
  }
}

.menu {
  position: relative;
  transform: translateX(-100vw);
  animation-duration: 0.4s;
  opacity: 0;
  animation: closeToggle 0.4s linear;
  pointer-events: none;

  @include media.media-breakpoint(mobile) {
    height: 100vh;
  }

  &--on {
    animation: openToggle 0.4s linear;
    transform: translateX(0);
    pointer-events: all;
    width: calc(100vw - 244px);
    padding: 122px;
    margin-top: -1px;
    display: flex;
    background-color: #fff;
    opacity: 1;
  }

  &__button {
    display: flex;
    margin-bottom: 24px;
    margin-left: 14px;
  }

  &__content {
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.item {
  height: 34.667px;
  flex-shrink: 0;
  color: #6170b5;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.07px;
  text-align: center;
  margin-top: 49.33px;
  text-decoration: none;
  cursor: pointer;

  &__first {
    margin-top: 0;
  }

  &--on {
    height: 84.667px;
  }

  &__list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;

    &--on {
      display: flex;
      max-height: 50px;
      height: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__link {
    color: #6170b5;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.07px;
    margin-bottom: 8px;
  }
}

@keyframes openToggle {
  0% {
    transform: translate(100vw);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes closeToggle {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translateX(100vw);
  }
}
