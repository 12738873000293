@use 'src/utils/scss/media';

.top-button {
  z-index: 1000;
  position: fixed;
  right: 30px;
  bottom: 15px;
}

.tab {
  cursor: pointer;
}
