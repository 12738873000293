@use 'src/utils/scss/media';

.project-button {
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  
  width: 496px;
  // height: 428px;
  border-radius: 12px;
  background: #fff;

  &:hover {
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04),
      0px 8px 32px 0px rgba(0, 0, 0, 0.08);
  }

  @include media.media-breakpoint(mobile) {
    display: flex;
    width: 160px;
    height: auto;
    padding: 4px;
    flex-direction: column;

    flex-shrink: 0;

    &:hover {
      box-shadow: none;
    }
  }
}

.project-image {
  border-radius: 12px;
  height: 330px;
  @include media.media-breakpoint(mobile) {
    border-radius: 8px;
    height: 120px;
  }
}

.project-info {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  @include media.media-breakpoint(mobile) {
    padding: 0;
  }

  &__title {
    color: #1f1f1f;

    /* Bold/22pt */
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 35.2px */
    letter-spacing: -0.242px;

    @include media.media-breakpoint(mobile) {
      padding: 8px 4px 0px 4px;
      color: #000;

      /* Medium/12pt */
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__designers {
    color: #8e8e8e;

    /* Medium/20pt */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
  }
}
