@use 'src/utils/scss/media';

.tab-button-section {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 51px;
  transition: none;

  @include media.media-breakpoint(mobile) {
    position: fixed;
    width: 100%;
    z-index: 100;
    gap: 0px;
    margin-top: 0px;
    background-color: white;
    transition: transform 0.3s ease;

    &--hide {
      transform: translateY(-101%);
      transition: transform 0.3s ease;
    }
  }
}

.project-tab-button-section {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 68px;
  @include media.media-breakpoint(mobile) {
    gap: 8px;
    margin-top: 40.5px;
  }
}

.tab-button {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Primary-10, #f5f8ff);
  border: none;
  cursor: pointer;

  @include media.media-breakpoint(mobile) {
    border-radius: 0px;
    padding: 12px 0px;
    width: 187.5px;
    background-color: transparent;
    border-bottom: 1.5px solid var(--Primary-50, #7ea1db);
  }

  & > span {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    opacity: 1;
    color: var(--Primary-50, #7ea1db);
    line-height: 160%; /* 44.8px */
    letter-spacing: -0.308px;

    @include media.media-breakpoint(mobile) {
      color: var(--Primary-50, #7ea1db);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &--unchecked {
    color: var(--Primary-50, #7ea1db);
    font-family: Pretendard;
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: none;
    background-color: transparent;

    @include media.media-breakpoint(mobile) {
      border-radius: 0px;
      padding: 12px 0px;
      width: 187.5px;
      border-bottom: 1.5px solid var(--Primary-50, #eaeaea);
    }

    & > span {
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      color: var(--Primary-50, #7ea1db);
      line-height: 160%; /* 44.8px */
      letter-spacing: -0.308px;

      @include media.media-breakpoint(mobile) {
        color: #898989;
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 25.6px */
      }
    }
  }
}

.project-tab-button {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Primary-10, #f5f8ff);
  border: none;

  @include media.media-breakpoint(mobile) {
    border-radius: 0px;
    padding: 6px 16px;
    border-radius: 8px;
    width: fit-content;
  }

  & > span {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    opacity: 1;
    color: var(--Primary-50, #7ea1db);
    line-height: 160%; /* 44.8px */
    letter-spacing: -0.308px;

    @include media.media-breakpoint(mobile) {
      color: var(--Primary-50, #7ea1db);

      /* Bold/16pt */
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &--unchecked {
    color: var(--Primary-50, #7ea1db);
    font-family: Pretendard;
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: none;
    background-color: transparent;

    @include media.media-breakpoint(mobile) {
      border-radius: 0px;
      padding: 6px 16px;
      width: fit-content;
    }

    & > span {
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      color: var(--Primary-50, #7ea1db);
      line-height: 160%; /* 44.8px */
      letter-spacing: -0.308px;

      @include media.media-breakpoint(mobile) {
        color: var(--Primary-50, #7ea1db);

        /* Regular/16pt */
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
      }
    }
  }
}
