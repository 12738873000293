body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css 파일에 추가할 코드 */
@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-Thin.woff2') format('woff2');
  font-weight: 100; /* Thin weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-ExtraLight.woff2') format('woff2');
  font-weight: 200; /* ExtraLight weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-Light.woff2') format('woff2');
  font-weight: 300; /* Light weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-Regular.woff2') format('woff2');
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-Medium.woff2') format('woff2');
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-SemiBold.woff2') format('woff2');
  font-weight: 600; /* SemiBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-Bold.woff2') format('woff2');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-ExtraBold.woff2') format('woff2');
  font-weight: 800; /* ExtraBold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('fonts/Pretendard-Black.woff2') format('woff2');
  font-weight: 900; /* Black weight */
  font-style: normal;
}

body {
  font-family: 'Pretendard', Sans-serif;
}
