@use 'src/utils/scss/media';

.template {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 83px;
  margin-bottom: 83px;

  @include media.media-breakpoint(mobile) {
    width: 100%;
    flex-direction: column;
    margin-top: 0;
  }
}

.profile {
  display: flex;
  align-items: center;

  @include media.media-breakpoint(mobile) {
    width: 100%;
    flex-direction: column;
  }

  &__image {
    width: 444px;
    height: 562px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;

    @include media.media-breakpoint(mobile) {
      width: 100%;
      height: 492px;
      flex-direction: column;
      background-color: #222;
      position: relative;
    }
  }

  &__img {
    width: 444px;
    height: 562px;
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    object-fit: cover;

    @include media.media-breakpoint(mobile) {
      width: 100%;
      height: 492px;
      border-radius: 0;
      flex-direction: column;
      background-color: #222;
      position: relative;
    }
  }
  &__info {
    width: 564px;
    margin-left: 132px;

    @include media.media-breakpoint(mobile) {
      width: calc(100% - 48px);
      padding: 0 24px;
      margin: 0;
    }
  }
}

.name {
  display: flex;
  margin-bottom: 23px;

  @include media.media-breakpoint(mobile) {
    display: none;
  }

  &--mobile {
    display: none;

    @include media.media-breakpoint(mobile) {
      display: flex;
      position: absolute;
      height: 38px;
      bottom: 16px;
      left: 32px;
    }
  }

  &__title {
    color: #262626;
    font-family: Pretendard;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
    letter-spacing: -0.44px;

    &--mobile {
      display: none;

      @include media.media-breakpoint(mobile) {
        display: flex;
        color: #fff;
        font-family: Pretendard;
        height: 38px;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 38.4px */
        letter-spacing: -0.264px;
      }
    }
  }

  &__en {
    color: #bfbfbf;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.6px;
    text-align: center;
    margin-top: auto;
    margin-left: 8px;

    &--mobile {
      display: none;

      @include media.media-breakpoint(mobile) {
        display: flex;
        color: #fff;
        font-family: Pretendard;
        height: 29px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
        margin-top: auto;
        margin-left: 7px;
        margin-bottom: 2px;
      }
    }
  }
}

.word {
  width: calc(100% - 32px);
  height: 59px;
  padding: 16px;
  border-radius: 8px;
  background: #f5f8ff;
  margin-bottom: 36px;

  @include media.media-breakpoint(mobile) {
    width: calc(100% - 32px);
    padding: 16px;
    height: auto;
    margin-top: 21px;
    margin-bottom: 21px;
  }

  &__header {
    color: #b9d3ff;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;

    @include media.media-breakpoint(mobile) {
      color: #b9d3ff;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__content {
    color: #7ea1db;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 32px */
    letter-spacing: -0.22px;

    @include media.media-breakpoint(mobile) {
      color: #7ea1db;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.links {
  display: flex;
  flex-direction: column;
  color: #222;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */

  @include media.media-breakpoint(mobile) {
    color: #222;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
  }

  &__item {
    min-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 8.5px;
  }

  &__link {
    text-decoration: none;
    border: none;
    color: #222;
  }

  &__logo {
    margin-right: 13px;

    @include media.media-breakpoint(mobile) {
      width: 16px;
      height: 16px;
    }
  }
}

.qa {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 126px;
  margin-bottom: 126px;

  @include media.media-breakpoint(mobile) {
    margin: 45px 0;
    padding: 0 24px;
    width: calc(100% - 48px);
  }

  &__title {
    width: 83px;
    color: #6170b5;
    font-family: Pretendard;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
    letter-spacing: -0.44px;
    border-bottom: 3px solid #6170b5;
    justify-content: center;
    align-items: center;

    @include media.media-breakpoint(mobile) {
      width: 39px;
      color: #6170b5;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 1136px;

    @include media.media-breakpoint(mobile) {
      width: 100%;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;

  @include media.media-breakpoint(mobile) {
    width: 100%;
  }

  &__head {
    width: 100%;
    color: #454545;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.264px;
    float: left;

    @include media.media-breakpoint(mobile) {
      float: top;
      color: #222;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-bottom: 0.5px solid #6888be;
      padding-bottom: 9px;
      margin-bottom: 9px;
    }
  }

  &__tail {
    color: #222;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
    margin-left: auto;

    @include media.media-breakpoint(mobile) {
      color: #222;
      text-align: justify;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 24px */
      margin-bottom: 31px;
    }
  }

  &__answer {
    width: 564px;

    @include media.media-breakpoint(mobile) {
      width: 100%;
    }
  }
}

.top-button {
  z-index: 1000;
  position: fixed;
  right: 30px;
  bottom: 15px;
}

.more {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__title {
    color: #6170b5;
    font-family: Pretendard;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.44px;
    border-bottom: 3px solid #6170b5;

    @include media.media-breakpoint(mobile) {
      color: #6170b5;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0;
      border-bottom: 1.5px solid #6170b5;
    }
  }

  &__content {
    margin-top: 77px;
    display: flex;
    //width: 64%;
    max-width: 1144px;
    width: 100%;
    justify-content: space-between;

    @include media.media-breakpoint(mobile) {
      margin-top: 44px;

      flex-direction: column;
      gap:23px;
      max-width: 325px;
      width: 100%;
    }
  }
}

.card {
  &__img {
    width: 496px;
    height: 324px;
    flex-shrink: 0;
    border-radius: 8px;
    object-fit: cover;

    @include media.media-breakpoint(mobile) {
      width: 100%;
      height: 217px;
      border-radius: 12px;
    }
  }

  &__title {
    color: #6888be;
    font-family: Pretendard;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.308px;

    @include media.media-breakpoint(mobile) {
      color: #1f1f1f;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &--project {
      color: #1f1f1f;
      font-family: Pretendard;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.308px;

      @include media.media-breakpoint(mobile) {
      color: #1F1F1F;

      /* Bold/18pt */
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      }


    }
  }

  &__content {
    color: #8e8e8e;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;

    @include media.media-breakpoint(mobile) {
      color: #8e8e8e;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 25.6px */
    }
  }

  &__description {
    padding: 16px 24px;

    @include media.media-breakpoint(mobile) {
      max-width: 277px;
      width: calc(100% - 48px);
      padding: 16px 24px;
    }

    &--project {
      max-width: 448px;
      width: calc(100% - 48px);
      padding: 16px 24px;
    }
  }
}
