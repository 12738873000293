@use 'src/utils/scss/media';

.section {
  width: 80vw;
  padding: 0 10vw;
  height: calc(100vh - 78px);
  position: relative;
  background-image: url('Asset/BG.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* 이미지 고정 */

  &__image {
    position: absolute;
    right: 60px;
    bottom: 223px;
  
    &--seed {
      width: 47vw;
      height: 90vh;
    }
  }

  &__arrow {
    position: absolute;
    height: 42px;
    width: 20px;
    bottom: 81px;
    right: 100px;
    z-index: 99;
    cursor: pointer;
  }
}

.description {
  position: absolute;
  width: 447px;
  bottom: 117px;
  display: flex;
  flex-direction: column;
  z-index: 102;

  &__intro {
    color: #636fb4;
    font-family: Pretendard;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
    letter-spacing: -0.308px;
    margin-bottom: 11px;
  }

  &__title {
    color: #4d4d4d;
    font-family: Pretendard;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.44px;
    margin-bottom: 21px;
  }

  &__content {
    color: #4d4d4d;
    font-family: Pretendard;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: -0.242px;
  }
}
