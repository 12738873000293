@use 'src/utils/scss/media';

.mobile-footer-section {
  width: calc(100vw - 49px);
  padding: 39px 25px 42px 24px;
  background: #f2f2f2;
  position: absolute;
  flex-direction: column;

  &__year {
    color: #000;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
  }

  &__titleSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
  }

  &__title {
    color: #000;

    /* Bold/18pt */
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__snsSection {
    display: flex;
    gap: 12px;
  }

  &__address {
    color: #000;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.3px; /* 152.5% */
    margin-bottom: 24px;
  }

  &__copyright {
    color: #000;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.footer-section {
  padding: 75px 0px 62px 200px;
  background: #f2f2f2;

  &__year {
    margin-top: 16px;
    color: #434343;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
  }

  &__address {
    margin-top: 8px;
    color: #434343;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }

  &__copyright {
    color: #434343;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
  }
}

.footer-sponsor-section {
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  padding: 24px 200px;
  

  &__icon {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  &__sns {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.bala-icon {
  width: 331px;
  height: 47px;
}

.footer {
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
}